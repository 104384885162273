import { v4 as uuidv4 } from 'uuid';

const cookieOptions = { path: '/', domain: process.env.DOMAIN };
const COOKIE_MAP = {
    ac_src: { options: cookieOptions, src: 'url' },
    ac_cmpgn: { options: cookieOptions, src: 'url' },
    ac_grp: { options: cookieOptions, src: 'url' },
    ac_trg: { options: cookieOptions, src: 'url' },
    referral_code: { options: cookieOptions, src: 'url' },
    session_id: { options: cookieOptions, src: () => uuidv4() },
    visitor_id: {
        options: { ...cookieOptions, expires: new Date(new Date().setMonth(new Date().getMonth() + 1)) },
        src: () => uuidv4(),
    },
};

const getURLparams = (asPath) => {
    if (asPath.split('?').length > 1) {
        const search = asPath.split('?').slice(-1)[0];
        const urlParams = new URLSearchParams(search);
        return Object.fromEntries(urlParams.entries());
    } else {
        return {};
    }
};

export const updateCookies = (router, cookies, setCookie, removeCookie) => {
    const urlParams = getURLparams(router.asPath);

    // this is to make sure that we do not retain an old campaign, group or target when source changes
    if ('ac_src' in urlParams) {
        setCookie('ac_src', urlParams['ac_src'], cookieOptions);
        ['ac_cmpgn', 'ac_grp', 'ac_trg'].forEach((ac_hierachy) => {
            if (ac_hierachy in urlParams) {
                setCookie(ac_hierachy, urlParams[ac_hierachy] || null, cookieOptions);
            } else {
                removeCookie(ac_hierachy, cookieOptions);
            }
        });
    }

    for (const [key, params] of Object.entries(COOKIE_MAP)) {
        if (params.src === 'url' && key in urlParams && !key.includes('ac')) {
            setCookie(key, urlParams[key], params.options);
        } else if (params.src !== 'url') {
            if (cookies[key] && cookies[key] !== 'undefined') {
                // refresh cookie
                setCookie(key, cookies[key], params.options);
            } else {
                setCookie(key, params.src(), params.options);
            }
        }
    }
};

export const sendEvent = (type, data = {}) => {
    data.env = process.env.ENV;
    // clear previous data
    window.dataLayer.push({
        content: '',
    });
    window.dataLayer.push({
        event: type,
        content: data,
    });
};

export const nodeInteractionTracking = ({node_id, paramName}) => ({pre_interaction_value, post_interaction_value}) => {
    sendEvent('node_interaction', {
        node_id,
        interaction_name: `${paramName}_changed`,
        pre_interaction_value,
        post_interaction_value,
        event_data: { category: 'behavior', label: node_id },
    });
};

export const getMarketingParams = (queryParams) => {
    const parseUTMParams = () => {
        const utm_source = get(queryParams, 'utm_source');
        const utm_medium = get(queryParams, 'utm_medium');
        const utm_campaign = get(queryParams, 'utm_campaign');
        const utm_content = get(queryParams, 'utm_content');
        const utm_term = get(queryParams, 'utm_term');
        let utmParams;
        if (utm_source && utm_medium && utm_campaign) {
            utmParams = {
                utm_source,
                utm_medium,
                utm_campaign,
            };
            if (utm_content) utmParams = { ...utmParams, utm_content };
            if (utm_term) utmParams = { ...utmParams, utm_term };
            return utmParams;
        }
        return null;
    };
    const parseOMParams = () => {
        const om_cmpgn = get(queryParams, 'om_cmpgn');
        const om_grp = get(queryParams, 'om_grp');
        const om_medium = get(queryParams, 'om_medium');
        const om_src = get(queryParams, 'om_src');
        const om_trg = get(queryParams, 'om_trg');

        let omParams;
        if (om_src && om_medium && om_cmpgn) {
            omParams = {
                om_src,
                om_medium,
                om_cmpgn,
            };
            if (om_grp) omParams = { ...omParams, om_grp };
            if (om_trg) omParams = { ...omParams, om_trg };
            return omParams;
        }
        return null;
    };

    const omParams = parseOMParams(queryParams);
    const utmParams = parseUTMParams(queryParams);

    let marketingParams;
    if (omParams && utmParams) {
        marketingParams = {
            ...omParams,
            ...utmParams,
        };
        return marketingParams;
    }
    if (omParams && !utmParams) {
        marketingParams = { ...omParams };
        return marketingParams;
    }
    if (utmParams && !omParams) {
        marketingParams = { ...utmParams };
        return marketingParams;
    }
    return null;
};

// export const setupTestGroups = () => () => {
//     const visitor_cookie = Cookies.get('visitor');
//     const HL_AB_TEST_cookie = Cookies.get('HL-AB-TEST');
//     return getTestGroupsData(AB_TESTS, visitor_cookie, HL_AB_TEST_cookie);
// };
// export const getTestGroups = setupTestGroups();
